import moment from "moment/moment";
import React, { useState, useEffect, useRef } from "react";
import {
  dateObjectToDate,
  dateToDateObject,
  getCookie,
} from "../global/Global";
import {
  getStorageValue,
  setStorageValue,
  clearStorageValue,
} from "../global/Storage";
import ArticleEditor, { dataParse } from "./ArticleEditor";
import Checkbox from "./Checkbox";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import Tooltip from "./Tooltip";
import DefaultAvatar_150 from "../default-avatar_150.png";

import { DtPicker } from "react-calendar-datetime-picker";
import "react-calendar-datetime-picker/dist/style.css";
import Input from "./Input";
import _ from "lodash";
import {
  faList,
  faMicrochip,
  faReply,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckboxSwitcher from "./CheckboxSwitcher";

const ReplyForm = ({
  className,
  onReply,
  threadid,
  forumid,
  user,
  title,
  collapsed,
  replyPost = null,
  replyPostEnabled = true,
  setReplyPostEnabled = null,
  minimal = false,
  textHintMinimal = null,
  editorSource = null,
}) => {
  const [editor, setEditor] = useState(1);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [minimalCollapsed, setMinimalCollapsed] = useState(true);
  const [pageData, setPageData] = useState({});
  const [threadTitle, setThreadTitle] = useState("");
  const [tags, setTags] = useState("");
  const [forums, setForums] = useState([]);
  const [selectionForum, setSelectionForum] = useState();
  const [selectionForumTitle, setSelectionForumTitle] = useState();
  const [originalContent, setOriginalContent] = useState(false);
  const [adult, setAdult] = useState(false);
  const [hidePostFromSocialNetworks, setHidePostFromSocialNetworks] =
    useState(false);
  const [noAutoForumDetection, setNoAutoForumDetection] = useState(false);
  const [isDataSending, setDataSending] = useState(false);
  const [schedulePublication, setScedulePublication] = useState(false);
  const [scheduleTime, setSceduleTime] = useState(new Date());
  const [privateAnswer, setPrivateAnswer] = useState(false);
  const scheduleTimeProtector = useRef();
  const threadTitleEl = useRef();
  const tagsInput = useRef();
  const storageSaveEl = useRef();
  const forumSelectionEl = useRef();

  const [searchTagList, setSearchTagList] = useState([]);
  const [searchForumList, setSearchForumList] = useState([]);
  const [AIProcessing, setAIProcessing] = useState(false);

  const ignoreTagList = [];
  let searchTagTimeout = useRef(null);
  let tabTagTimeout = useRef(null);
  let tabTagIndex = useRef(0);
  let tabTagPattern = useRef(null);
  let searchForumTimeout;

  let storagePreDataTimeout = useRef();
  let storageTitleTimeout;

  const searchTag = async (tagSeach) => {
    if (!tagSeach) {
      setSearchTagList([]);
      return;
    }

    if (!tagSeach.trim()) {
      setSearchTagList([]);
      return;
    }

    const data = await window.TALKVIOAPI("tag", {
      search: tagSeach,
    });
    logT("tags", "search result", tagSeach, "result", data?.tags);
    if (data?.tags) {
      setSearchTagList(
        data.tags.map((tag) => Object.assign(tag, { pattern: tagSeach })),
      );
    }
  };

  const searchForum = async (forumSearch) => {
    if (!forumSearch || forumSearch.length == 0) {
      setSearchForumList([]);
      return;
    }

    const data = await window.TALKVIOAPI("forums", {
      search: forumSearch,
    });
    if (data.forums?.length == 1) {
      setSearchForumList([]);
      setSelectionForum(data.forums[0]);
      setSelectionForumTitle(data.forums[0].title);
    } else if (data.forums?.length > 1) {
      setSearchForumList(data.forums);
    } else {
      setSearchForumList([]);
    }
  };

  const loadStorageData = async () => {
    let storage;

    if (!threadid && !forumid) {
      storage = await getStorageValue("editor:" + editor);
    } else if (threadid) {
      storage = await getStorageValue(`editor:thread:${threadid}`);
    } else if (forumid) {
      storage = await getStorageValue(`editor:forum:${forumid}`);
    }

    logT("replyform", "load storage");

    if (!storage) {
      setPageData({});
      setThreadTitle("");
      setTags("");
      setSelectionForum(null);
      setOriginalContent(false);
      setAdult(false);
      setHidePostFromSocialNetworks(false);
      setNoAutoForumDetection(false);
      setScedulePublication(false);
      setSceduleTime(new Date());
      setPrivateAnswer(false);
      if (threadTitleEl?.current) threadTitleEl.current.value = "";
      if (tagsInput?.current) tagsInput.current.value = "";
      return;
    }

    setPageData(storage.data);
    setThreadTitle(storage.threadTitle);
    setTags(storage.tags);
    setSelectionForum(storage.selectionForum);
    setOriginalContent(storage.originalContent);
    setAdult(storage.adult);
    setHidePostFromSocialNetworks(storage.hidePostFromSocialNetworks);
    setNoAutoForumDetection(storage.noAutoForumDetection);
    setScedulePublication(storage.schedulePublication);
    setPrivateAnswer(storage.privateAnswer);
    let storageDate = new Date(storage.scheduleTime);
    if (storageDate > new Date()) setSceduleTime(storageDate);
    else setSceduleTime(new Date());
    if (typeof storage.threadTitle == "string" && threadTitleEl?.current)
      threadTitleEl.current.value = storage.threadTitle;
    if (typeof storage.tags == "string" && tagsInput?.current)
      tagsInput.current.value = storage.tags;
    if (minimal) {
      if (
        storage.data?.blocks?.length > 1 ||
        storage.data?.blocks?.[0]?.type != "text" ||
        storage.data?.blocks?.[0]?.data != ""
      ) {
        setMinimalCollapsed(false);
      }
    }
  };

  const fetchForums = async () => {
    if (threadid || forumid || forums.length > 0) {
      return; // doesn't need
    }
    const data = await window.TALKVIOAPI("forums", {});
    setForums(data.forums);
  };

  useEffect(() => {
    loadStorageData();
    fetchForums();
  }, []);

  useDidUpdateEffect(() => {
    if (!threadid) return;

    loadStorageData();
  }, [threadid]);

  useDidUpdateEffect(() => {
    logT("editor", "editor change, cleanup");
    setSearchTagList([]);
    setSearchForumList([]);
    setSelectionForumTitle(null);
  }, [editor]);

  const setStorageDate = async (date) => {
    if (!storageSaveEl.current) return;

    storageSaveEl.current.innerText = `${__("Draft saved")} ${moment(date).format("MMM Do hh:mm:ss")}`;
    storageSaveEl.current.classList.remove("animate-top");
    void storageSaveEl.current.offsetWidth;
    storageSaveEl.current.classList.add("animate-top");
  };

  const saveStorageData = async (preData = null) => {
    const data = preData || pageData;

    if (storagePreDataTimeout.current) {
      clearTimeout(storagePreDataTimeout.current);
      storagePreDataTimeout.current = null;
    }

    setStorageDate(Date.now());
    if (!threadid && !forumid) {
      setStorageValue("editor:" + editor, {
        data: await dataParse(data),
        threadTitle,
        originalContent,
        selectionForum,
        adult,
        hidePostFromSocialNetworks,
        noAutoForumDetection,
        tags,
        schedulePublication,
        scheduleTime: scheduleTime && scheduleTime.toJSON(),
      });
    } else if (threadid) {
      // on replyes for each thread on one page enabled can allocate too much space, reduce saving
      if (
        data?.blocks?.length == 0 ||
        (data?.blocks?.length == 1 &&
          data?.blocks[0].type == "text" &&
          data?.blocks[0].data == "")
      ) {
        if (!(await getStorageValue(`editor:thread:${threadid}`))) {
          logT(
            "storage",
            "ignore saving thread storage as data blocks is empty",
          );
          return;
        }
      }
      setStorageValue(`editor:thread:${threadid}`, {
        data: await dataParse(data),
        originalContent,
        adult,
        privateAnswer,
      });
    } else if (forumid) {
      setStorageValue(`editor:forum:${forumid}`, {
        data: await dataParse(data),
        threadTitle,
        originalContent,
        selectionForum,
        adult,
        hidePostFromSocialNetworks,
        noAutoForumDetection,
        tags,
        schedulePublication,
        scheduleTime: scheduleTime && scheduleTime.toJSON(),
      });
    }
    logT(
      "replyform",
      "save storage values from",
      preData ? "pre-data" : "data",
    );
  };

  const clearStorageData = () => {
    if (!threadid && !forumid) {
      clearStorageValue("editor:" + editor);
    } else if (threadid) {
      clearStorageValue(`editor:thread:${threadid}`);
    } else if (forumid) {
      clearStorageValue(`editor:forum:${forumid}`);
    }
    logT("replyform", "clear storage values");
  };

  useEffect(() => {
    saveStorageData();
  }, [
    pageData,
    threadTitle,
    originalContent,
    adult,
    hidePostFromSocialNetworks,
    noAutoForumDetection,
    tags,
    schedulePublication,
    scheduleTime,
    selectionForum,
    privateAnswer,
  ]);

  useDidUpdateEffect(() => {
    loadStorageData();
  }, [editor]);

  return (
    user &&
    user.userid > 0 && (
      <div
        onFocus={() => {
          window.blockKeyTag("posts", "replyform");
          if (minimal) {
            logT("minimal", "collapsed = false");
            setMinimalCollapsed(false);
          }
        }}
        onBlur={(e) => {
          window.unblockKeyTag("posts", "replyform");
          if (minimal) {
            if (
              !e.currentTarget.contains(e.relatedTarget) &&
              !e.relatedTarget?.classList?.contains("article-text") &&
              (pageData?.blocks?.length == 0 ||
                (pageData?.blocks?.length == 1 &&
                  pageData?.blocks[0].type == "text" &&
                  pageData?.blocks[0].data == ""))
            ) {
              logT("minimal", "collapsed = true");
              setMinimalCollapsed(true);
            }
          }
        }}
        className={"replyForm" + (className ? ` ${className}` : "")}
      >
        {!collapsed || !isCollapsed ? (
          <div className="reply-block">
            {!threadid && (
              <div className="threadInputName">
                <input
                  placeholder={__("Title")}
                  ref={threadTitleEl}
                  onBlur={(e) => {
                    setThreadTitle(e.target.value);
                  }}
                  onInput={(e) => {
                    if (storageTitleTimeout) clearTimeout(storageTitleTimeout);
                    storageTitleTimeout = setTimeout(() => {
                      setThreadTitle(e.target.value);
                    }, 400);
                  }}
                />
              </div>
            )}
            {replyPost &&
            replyPostEnabled &&
            replyPost.userid != user.userid &&
            !minimal ? (
              <div className="replyToPost flex row w100p center-column wrap">
                <div className="replySymbol">
                  <FontAwesomeIcon icon={faReply} />
                </div>
                <div className="textReply">{__("Reply on post from")}</div>
                <div className="userReplyImg flex row center-column">
                  <img
                    src={
                      replyPost.avatarrevision > 0
                        ? `https://talkvio.com/customavatars/avatar${replyPost.userid}_${replyPost.avatarrevision}.gif`
                        : DefaultAvatar_150
                    }
                    alt={__("Avatar of user") + " " + replyPost.username}
                  />
                  <div className="userReplyName">{replyPost.username}</div>
                </div>
                <div
                  className="removePostReply"
                  onClick={() => {
                    if (setReplyPostEnabled) setReplyPostEnabled(false);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            ) : null}
            <ArticleEditor
              minimal={minimal}
              minimalCollapsed={minimalCollapsed}
              data={pageData}
              textHintMinimal={textHintMinimal}
              setData={setPageData}
              onPreData={(data) => {
                if (storagePreDataTimeout.current)
                  clearTimeout(storagePreDataTimeout.current);
                storagePreDataTimeout.current = setTimeout(() => {
                  storagePreDataTimeout.current = null;
                  saveStorageData(data);
                }, 400);
              }}
              editorSource={editorSource}
            />
            {!threadid && (
              <div className="tagsInput">
                <input
                  tabIndex={-1}
                  placeholder={__(
                    "Please add tags seprarated by commas or it will automatically generated",
                  )}
                  ref={tagsInput}
                  onBlur={(e) => {
                    setTags(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace") {
                      if (tabTagTimeout.current) {
                        logT("key", "clear timeout tag");
                        clearTimeout(tabTagTimeout.current);
                      }
                    } else if (e.key === "Tab") {
                      e.preventDefault();
                      logT("key", "autocomplete tag");

                      let text = tagsInput.current.value;
                      if (!text) return;
                      if (!searchTagList?.length) return;
                      const tag = searchTagList[tabTagIndex.current || 0];
                      if (!tag) return;
                      tabTagIndex.current =
                        (tabTagIndex.current + 1) % searchTagList.length;
                      text = text.split(",").map((tag) => tag.trim());
                      logT(
                        "pattern",
                        tabTagPattern.current || tag.pattern,
                        "replace with",
                        tag.tagtext,
                      );
                      text[text.length - 1] = text[text.length - 1].replace(
                        tabTagPattern.current || tag.pattern,
                        tag.tagtext,
                      );
                      tabTagPattern.current = tag.tagtext;
                      text = text.join(", ");
                      tagsInput.current.value = text;
                      setTags(text);

                      ignoreTagList.push(tag.tagtext);
                      if (tabTagTimeout.current)
                        clearTimeout(tabTagTimeout.current);
                      tabTagTimeout.current = setTimeout(() => {
                        tabTagIndex.current = 0;
                        tabTagPattern.current = null;
                        tabTagTimeout.current = null;
                        let text = tagsInput.current.value;
                        text += ", ";
                        tagsInput.current.value = text;
                        setTags(text);
                        logT("pattern", "clear setSearchTagList");
                        setSearchTagList([]);
                      }, 1000);
                    }
                  }}
                  onInput={async (event) => {
                    const text = event.target.value;
                    if (text) {
                      const textSplit = text
                        .split(",")
                        .map((tag) => tag.trim());
                      if (textSplit.length > 0) {
                        tabTagPattern.current = textSplit[textSplit.length - 1];
                      }
                    }
                    if (searchTagTimeout.current)
                      clearTimeout(searchTagTimeout.current);
                    searchTagTimeout.current = setTimeout(async () => {
                      setTags(text);

                      if (!text || text.length == 0) {
                        setSearchTagList([]);
                        return;
                      }

                      let tagMatch = /(?:^|,)[\s]*([^,$]+)/g;
                      let match;
                      const foundList = [];
                      while ((match = tagMatch.exec(text)) !== null) {
                        if (ignoreTagList.includes(match[1])) {
                          continue;
                        }
                        foundList.push(match[1]);
                      }
                      if (foundList.length > 0) {
                        searchTag(foundList[foundList.length - 1]);
                      }
                    }, 310);
                  }}
                />
                {!AIProcessing && user.karma >= 0 ? (
                  <Tooltip hint={__("Click to generate tags using ai")}>
                    <div
                      className="tagsAiButton"
                      onClick={async () => {
                        setAIProcessing(true);
                        const token = getCookie("token");
                        const userid = parseInt(getCookie("userid"));
                        const tags = await window.TALKVIOAPI("aiTags", {
                          token,
                          userid,
                          pageData: pageData,
                          title: threadTitle || "",
                        });
                        setAIProcessing(false);
                        if (tags.tags?.length > 0) {
                          logT("aiTags", "set tags", tags.tags);
                          const text = tags.tags.join(", ");
                          tagsInput.current.value = text;
                          setTags(text);
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faMicrochip} />
                    </div>
                  </Tooltip>
                ) : null}
              </div>
            )}
            {searchTagList && searchTagList.length > 0 ? (
              <ul className="tagSearchList">
                {searchTagList.map((tag) => (
                  <li
                    key={tag.tagid}
                    onClick={() => {
                      let text = tagsInput.current.value;
                      if (!text) return;
                      text = text.split(",").map((tag) => tag.trim());
                      text[text.length - 1] =
                        text[text.length - 1].replace(
                          tabTagPattern.current || tag.pattern,
                          tag.tagtext,
                        ) + ", ";
                      text = text.join(", ");
                      tagsInput.current.value = text;
                      setTags(text);

                      tabTagPattern.current = null;
                      ignoreTagList.push(tag.tagtext);
                      setSearchTagList([]);
                    }}
                  >
                    #{tag.tagtext}
                  </li>
                ))}
              </ul>
            ) : null}
            {!threadid ? (
              <div className="controls">
                <Tooltip
                  hint={__(
                    "Content of this post is created by you. As example it can be a picture your drew or music you have created or story you wrote.",
                  )}
                >
                  <Checkbox
                    text={__("Author's / original content (mine)")}
                    value={originalContent}
                    onSwitch={(checked) => setOriginalContent(checked)}
                  />
                </Tooltip>
                <Tooltip
                  hint={__(
                    "Mark post as NSFW. Any NSFW content must be marked with this.",
                  )}
                >
                  <Checkbox
                    text={__("Adult content / 18+")}
                    value={adult}
                    onSwitch={(checked) => setAdult(checked)}
                  />
                </Tooltip>
                <Tooltip hint={__("Set date when post will be published")}>
                  <Checkbox
                    text={__("Publication schedule")}
                    value={schedulePublication}
                    onSwitch={(checked) => setScedulePublication(checked)}
                  />
                </Tooltip>
                <Tooltip
                  hint={__("Disable post from reposts in social network")}
                >
                  <Checkbox
                    text={__("Hide post from social networks")}
                    value={hidePostFromSocialNetworks}
                    onSwitch={(checked) =>
                      setHidePostFromSocialNetworks(checked)
                    }
                  />
                </Tooltip>
                <Tooltip
                  hint={__(
                    "If you don't want to use automatic forum detection and make post without forum please select it",
                  )}
                >
                  <Checkbox
                    text={__("Disable AI-based topic detection")}
                    value={noAutoForumDetection}
                    onSwitch={(checked) => setNoAutoForumDetection(checked)}
                  />
                </Tooltip>
                {schedulePublication ? (
                  <DtPicker
                    showTimeInput={true}
                    autoClose={false}
                    withTime={true}
                    initValue={
                      scheduleTimeProtector?.current ||
                      dateToDateObject(scheduleTime)
                    }
                    onChange={(date) => {
                      if (!_.isEqual(date, scheduleTimeProtector?.current)) {
                        const time = dateObjectToDate(date);
                        logT("calendar", "choose", time);
                        scheduleTimeProtector.current = date;
                        setSceduleTime(time);
                      } else {
                        logT("calendar", "ignore same value", date);
                      }
                    }}
                    minDate={dateToDateObject(new Date())}
                    placeholder={__("select time")}
                    nextMonthBtnTitle={__("next")}
                    previousMonthBtnTitle={__("previous")}
                    fromLabel={__("from")}
                    toLabel={__("to")}
                    clockFromLabel={__("from")}
                    clockToLabel={__("to")}
                    clockLabel={__("clock")}
                  />
                ) : null}
              </div>
            ) : null}
            {!threadid && !forumid ? (
              <div className="forumName">
                {selectionForum?.forumid ? (
                  <>
                    <div className="selectedForumDesc">{__("Section")}: </div>
                    <div className="selectedForum">{selectionForum.title}</div>
                    <div
                      className="selectedForumRemove"
                      onClick={() => {
                        setSelectionForum(null);
                        setSelectionForumTitle(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </>
                ) : (
                  <Input
                    placeholderLeft={true}
                    placeholder={__("Section")}
                    value={selectionForumTitle}
                    onChange={async (text) => {
                      setSelectionForumTitle(text);
                      if (searchForumTimeout) clearTimeout(searchForumTimeout);
                      searchForumTimeout = setTimeout(async () => {
                        searchForum(text);
                      }, 310);
                    }}
                  ></Input>
                )}
                <div className="forumSelection">
                  <div className="forumSelectionIcon">
                    <FontAwesomeIcon icon={faList} />
                  </div>
                  <select
                    ref={forumSelectionEl}
                    value={JSON.stringify(selectionForum || { forumid: 0 })}
                    onChange={(event) => {
                      const forum = JSON.parse(event.target.value);
                      setSelectionForum(forum);
                      setSelectionForumTitle(forum.title);
                    }}
                  >
                    <option value={JSON.stringify({ forumid: 0 })}>
                      ({__("Don't select")})
                    </option>
                    {forums?.length > 0
                      ? forums.map((forum, i) => (
                          <option key={i} value={JSON.stringify(forum)}>
                            {forum.title}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            ) : null}
            {searchForumList?.length > 0 ? (
              <ul className="forumSearchList">
                {searchForumList.map((forum) => (
                  <li
                    key={forum.forumid}
                    onClick={() => {
                      setSearchForumList([]);
                      setSelectionForum(forum);
                      setSelectionForumTitle(forum.title);
                    }}
                  >
                    {forum.title}
                  </li>
                ))}
              </ul>
            ) : null}
            {(!minimal || !minimalCollapsed) && threadid && (
              <CheckboxSwitcher
                text={__("Answer privately")}
                value={privateAnswer}
                onSwitch={(checked) => setPrivateAnswer(checked)}
              />
            )}
            {!minimal || !minimalCollapsed ? (
              <div className="storageDate" ref={storageSaveEl}></div>
            ) : null}
            {!minimal || !minimalCollapsed ? (
              <div className="storageSwitcher">
                {!threadid && !forumid
                  ? [
                      ...Array(
                        Math.max(
                          3,
                          Math.min(10, 3 + (user?.karma ? user.karma : 0)),
                        ),
                      ),
                    ].map((x, i) => (
                      <Tooltip
                        key={i}
                        hint={__(
                          "Change draft to different one, previous will be saved.",
                        )}
                      >
                        <div
                          className={
                            "storageSwitch" + (editor == i + 1 ? " focus" : "")
                          }
                          onClick={() => setEditor(i + 1)}
                        >
                          {i + 1}
                        </div>
                      </Tooltip>
                    ))
                  : null}
              </div>
            ) : null}
            {!minimal || !minimalCollapsed ? (
              <div className="buttonRow">
                {isDataSending ? (
                  <div className="sendingData">
                    {__("Sending data in progress")}...
                  </div>
                ) : (
                  <>
                    <div
                      className="clickButton"
                      tabIndex="0"
                      style={{ cursor: "pointer" }}
                    >
                      {__("Save draft")}
                    </div>
                    <div
                      className="clickButton"
                      style={{ cursor: "pointer" }}
                      tabIndex="0"
                      onClick={async () => {
                        const token = getCookie("token");
                        const userid = parseInt(getCookie("userid"));
                        setDataSending(true);
                        const params = {
                          token,
                          userid,
                          text: await dataParse(pageData, true),
                          original: originalContent,
                          adult: adult,
                          private: threadid && privateAnswer,
                          hidePostFromSocialNetworks,
                          noAutoForumDetection,
                        };
                        if (threadid) params.threadid = parseInt(threadid);
                        if (forumid) params.forumid = parseInt(forumid);
                        if (!forumid && selectionForum?.forumid)
                          params.forumid = parseInt(selectionForum.forumid);
                        if (title || threadTitle)
                          params.title = title || threadTitle;
                        if (tags && tags.length > 0) params.tags = tags;
                        if (schedulePublication && scheduleTime) {
                          params.scheduleDate =
                            (scheduleTime.getTime() / 1000) | 0;
                        }
                        if (replyPost?.postid && replyPostEnabled) {
                          params.parentid = replyPost.postid;
                        }
                        const data = await window.TALKVIOAPI(
                          "addPost",
                          params,
                          {
                            before: () => {},
                            after: () => setDataSending(false),
                            chunkMode: true,
                          },
                        );
                        logT("replyform", "sended post");
                        window.displayError(data);
                        if (data.success) {
                          pageData?.blocks
                            ?.filter((block) => block.storageFile)
                            .forEach((block) => {
                              logT(
                                "storage",
                                "remove also file from storage",
                                block.storageFile,
                              );
                              clearStorageValue(block.storageFile);
                            });
                          setPageData({ blocks: [{ type: "text", data: "" }] });
                          if (threadTitleEl?.current?.value)
                            threadTitleEl.current.value = "";
                          setThreadTitle("");
                          setOriginalContent(false);
                          setAdult(false);
                          setHidePostFromSocialNetworks(false);
                          setNoAutoForumDetection(false);
                          clearStorageData();
                          setTags("");
                          setSearchTagList([]);
                          setSelectionForum(null);
                          setSelectionForumTitle(null);
                          setSearchForumList([]);
                          if (tagsInput?.current?.value)
                            tagsInput.current.value = "";
                          if (onReply) onReply(data);
                          //setIsCollapsed(true)
                        } else {
                          if (onReply) onReply(null);
                        }
                      }}
                    >
                      {threadid ? __("Reply") : __("Create")}
                    </div>
                  </>
                )}
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className="clickButton replyCollapsedButton"
            style={{ cursor: "pointer" }}
            onClick={() => setIsCollapsed(false)}
          >
            {threadid && <p>{__("Write a reply")}</p>}
            {forumid && <p>{__("Create a thread")}</p>}
          </div>
        )}
      </div>
    )
  );
};
export default ReplyForm;
